<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Rooming List</strong>
          </CCardHeader>
          <CCardBody>
     
            <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="10%" class="text-center">Tipo</th>
                    <th width="5%" class="text-center">Hab.</th>
                    <th width="35%" class="text-center">Cliente</th>
                    <th width="5%" class="text-center">Pax(s)</th>
                    <th width="5%" class="text-center">Desayuno(s)</th>
                    <th width="8%" class="text-center">F. Llegada</th>
                    <th width="8%" class="text-center">F. Salida</th>
                    <th width="5%" class="text-center">Noches</th>
                    <th width="5%" class="text-center">Total</th>
                    <th width="5%" class="text-center">Abonos</th>
                    <th width="5%" class="text-center">Estado</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in room_control_rc" :key="it">
                  <tr :class="BackgroundColor(item.state)">
                    <td class="text-left"> {{ item.type_room_name }}</td>
                    <td class="text-center"> {{ item.room_number }}</td>
                    <td class="text-left"> {{ item.client_name }}</td>
                    <td class="text-center"> {{ item.maximum_guest }}</td>
                    <td class="text-center"> {{ item.breakfasts }}</td>
                    <td class="text-center"> {{ item.start_date }}</td>
                    <td class="text-center"> {{ item.final_date }}</td>
                    <td class="text-center"> {{ item.quantity }}</td>
                    <td class="text-right"> {{ item.total }}</td>
                    <td class="text-right"> {{ item.balance }}</td>
                    <td class="text-left"> {{ State(item.state) }}</td>
                
                  </tr>
                </tbody>
              </table>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>
<style scoped>
  td{
    vertical-align: middle;
  }
  .bg-free{ background-color: #FADED2; color: #000;}
  .bg-occupied{ background-color: #A0CBEE; color: #000;}
  .bg-waiting-for-maintenance{ background-color: #FE1859; color: #fff;}
  .bg-maintenance{ background-color: #FE1859; color: #fff;}
  .bg-info-room{ background-color: #455a64; color: #fff;}
  .bg-info-expire{ background-color: #F27280; color: #000;}

  .bg-info-button-room{ background-color: #536DFE; color: #fff;}


  .box {
    width: 200px; height: 300px;
    position: relative;
    border: 1px solid #BBB;
    background: #EEE;
  }
  .ribbon {
    position: absolute;
    right: 12px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px; height: 75px;
    text-align: right;
  }
  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #71CC49;
    background: linear-gradient(#9BC90D 0%, #71CC49 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
  }
  .ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #71CC49;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #71CC49;
  }
  .ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #71CC49;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #71CC49;
  }
  
</style>>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";


export default {
  name: "ControlHabitacionList",
  components:{
  
  },
  data() {
    return {
      module:'RoomControl',
      search:'',
    };
  },
  mounted() {
    this.mLoadListRoomControl();
   
  },
  methods: {
    ChangeSearch,
    ShowModalMinibar,
    ShowModalConsumer,
    ShowModalRoom,
    ShowModalNewRoomControl,
    ShowModalPayment,
    ShowModalPaymentAccount,
    ShowModalRoomControlDetail,
    ShowModalRoomToExpire,
    ShowModalReservedRoom,
    ShowModalHouseKeeping,

    ConfirmCancel,
    Cancel,
    ConfirmEmpty,
    Empty,
    ConfirmBreakFree,
    BreakFree,

    ExportExcel,
    
    BackgroundColor,
    State,
    StateExpire,

    VerifyReservation,

    ...mapActions('RoomControl',['mLoadListRoomControl']),
    ...mapActions('RoomControl',['mShowModalMinibar']),
    ...mapMutations('RoomControl',['mListRoomControl','mSetSearch'])
  },

  computed: {
    ...mapState(["url_base"]),
    ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ChangeSearch() {
  this.mSetSearch(this.search);
}
function State(state) {
  let TextState = 'LIBRE';
   switch (state) {
    case 1: TextState = 'OCUPADO'; break;
    case 2: TextState = 'A MANTENIMIENTO'; break;
    case 3: TextState = 'EN MANTENIMIENTO'; break;
    default: break;
  }
  return TextState;
}

function StateExpire(state) {
  if (state == 0) {
    return 'bg-info-room text-white';
  } else {
    return 'bg-info-expire text-white';
  }
}

function BackgroundColor(state) {
    // 0 libre 
    // 1 ocupado 
    // 2 libre para mantenimiento 
    // 3 en mantenimiento 
    // 4 finalizado 
    // 5 reservado 
    // 6 anulado 
    // 9 eliminado 
  let BackgroundColor = 'bg-free';
  switch (state) {
    case 1: BackgroundColor = 'bg-occupied'; break;
    case 2: BackgroundColor = 'bg-waiting-for-maintenance'; break;
    case 3: BackgroundColor = 'bg-maintenance'; break;
    // case 4: BackgroundColor = 'bg-warning'; break;
    // case 5: BackgroundColor = 'bg-warning'; break;
    // case 6: BackgroundColor = 'bg-warning'; break;
    // case 7: BackgroundColor = 'bg-info'; break;
    default: break;
  }

  return BackgroundColor;
  
}
// abrir modal frigobar
function ShowModalMinibar(id_room_control) {
  EventBus.$emit('ModalMinibarShow',id_room_control);
}
// abrir modal consumer
function ShowModalConsumer(id_room_control) {
  EventBus.$emit('ModalConsumerShow',id_room_control);
}
// abrir modal consumer
function ShowModalRoom(id_room,id_room_control) {
  // let data = { id_room:id_room,id_room_control:id_room_control };
  EventBus.$emit('ModalRoomShow',id_room,id_room_control);
}
// abrir modal nuevo control habitacion
function ShowModalNewRoomControl(id_room) {
  EventBus.$emit('ModalNewRoomControlShow',id_room);
}
// abrir modal nuevo control habitacion
function ShowModalPayment(id_room_control) {
  EventBus.$emit('ModalPaymentShow',id_room_control);
}
function ShowModalPaymentAccount(id_room_control) {
  EventBus.$emit('ModalPaymentAccountShow',id_room_control);
}


function ShowModalRoomControlDetail(id_room,id_room_control) {
  EventBus.$emit('ModalRoomControlDetailShow',id_room,id_room_control);
}

function ShowModalRoomToExpire() {
  EventBus.$emit('ModalRoomToExpire');
}

function ShowModalReservedRoom() {
  EventBus.$emit('ModalReservedRoom');
}

function ShowModalHouseKeeping(id_room_control) {
  EventBus.$emit('ModalHousekeepingShow',id_room_control);
}

function ConfirmCancel(id_room_control) {
    let me = this;
    Swal.fire({
    title: 'Esta seguro de Anular la estadia de la habitacion?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      me.Cancel(me,id_room_control);
    }
  })
}


function Cancel(_this,id_room_control) {

  let me = _this;
  let url = _this.url_base + "room-control/cancel/" + id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: _this.token,
      module: _this.module,
      role: 4,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: 'Se anulo la habitación', timer: 2000,})
          me.mLoadListRoomControl();
      }else if (response.data.status == 400){
        Swal.fire({ icon: 'error', text: 'No se puede anular la habitación seleccionada', timer: 2000,})
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurido un error', timer: 2000,})
      }
    })

}


function ConfirmEmpty(id_room_control) {
    let me = this;
    Swal.fire({
    title: 'Esta seguro de pasar a mantenimiento la habitación?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      me.Empty(me,id_room_control);
    }
  })
}

function Empty(_this,id_room_control) {
  let me = _this;
  let url = me.url_base + "room-control/maintenance/" + id_room_control;
  axios({
    method: "GET",
    url: url,
    headers: {
      token: me.token,
      module: me.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
            me.mLoadListRoomControl();
      } else {
          Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
}

function ConfirmBreakFree(id_room_control) {
    let me = this;
    Swal.fire({
    title: 'Esta seguro de liberar la habitación?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, Estoy de Acuerdo!'
  }).then((result) => {
    if (result.isConfirmed) {
      me.BreakFree(me,id_room_control);
    }
  })
}


function BreakFree(_this,id_room_control) {

  let me = _this;
  let url = me.url_base + "room-control/breakfree/" + id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: me.token,
      module: me.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
            me.mLoadListRoomControl();
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function VerifyReservation(reservation) {

  if (reservation == 0) {
    return 'd-none';
  }else{
    return 'ribbon';
  }
  
  
    

}

function ExportExcel() {
  let me = this;
  let url = this.url_base + "export-room-control";
  
  window.open(url,'_blank');
}


// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
